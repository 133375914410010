import { orderStatusMapper } from '@/api/thunks/order.const'
import { useAppSelector } from '@/store/redux/hooks'
import { addressGeneratorFromWaypoint, muskContinuesValue } from '@/utils'
import { CaseCardProps } from '@/utils/types'
import { Icon } from '@iconify/react/dist/iconify.js'
import { cx } from 'class-variance-authority'
import dayjs from 'dayjs'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const CaseCard: React.FC<CaseCardProps> = ({
  caseId,
  patientDetails,
  assignmentDetails,
  overallStatus,
  caseSource,
  totalAmount,
  patientAttendantDetails,
  waypoints,
  createdAt,
}) => {
  const {clientMap} = useAppSelector(app => app.userStore)
  const navigation = useNavigate();
  const pickupAddress =
    (waypoints &&
      waypoints?.length &&
      waypoints[0] &&
      addressGeneratorFromWaypoint(waypoints[0])) ||
    null

  const caseOjb = orderStatusMapper[overallStatus]

  return (
    <div className='flex w-full items-start justify-between rounded-lg border-[1px] p-4 shadow-sm'>
      <div className='flex items-start'>
        <div className='mt-1 flex h-20 w-20 items-center justify-center rounded-md border-[1px] bg-white p-2'>
          {/* <FiTruck className="text-red-500 text-3xl" /> */}
          <Icon
            icon='hugeicons:ambulance'
            fontSize={30}
            className='text-6xl text-red-500'
          />
        </div>
        <div className='ml-4'>
          <div className='flex items-center'>
            <h2 className='text-lg font-light'>
              Case ID: <span className='font-bold'>{caseId}</span>
            </h2>
            <span className={cx('ml-3 rounded-full  px-3 py-1 text-xs font-semibold  ', caseOjb?.className ? `${caseOjb?.className}`: 'bg-red-500 text-white')}>
              {caseOjb?.label || '-'}
            </span>
          </div>

          <p className='text-base font-light text-gray-600'>
            Patient:{' '}
            <span className='font-medium text-gray-800'>
              {patientDetails.name}
            </span>
            {/* , {patientDetails?.age} years old, {patientDetails?.gender} */}
          </p>
          <p className='text-base font-light text-gray-500'>
            Pickup Location:{' '}
            {pickupAddress ? (
              <span className='font-medium text-gray-800'>
                {muskContinuesValue(pickupAddress, 20, 4.5, 'suffix')}
              </span>
            ) : (
              '-'
            )}
          </p>
          <p className='text-base font-light text-gray-500'>
            Source:{' '}
            <span className='font-medium text-gray-800'>
              {' '}
              {caseSource?.source && clientMap[caseSource?.source]?.clientName || '-'}
            </span>
          </p>
        </div>
      </div>
      <div className='text-right text-base'>
        <p className='whitespace-nowrap text-base font-light text-gray-500'>
          Case created on:
          {/* {`${dayjs('1718688370598').format('DD MMM YYYY  hh:mm A')} `} */}
          <span className='ml-2 font-medium text-gray-800'>
            {dayjs
              .unix((createdAt || '1718688370598') / 1000)
              .format('DD MMM YYYY  hh:mm A')}
          </span>
          {/* {dayjs.unix((extractValue("createdAt") || 1000) / 1000).format("DD MMM YYYY hh:mm A")} */}
        </p>

        <p className='whitespace-nowrap text-base font-light text-gray-500'>
          Phone Number:{' '}
          <span className='ml-2 font-medium text-gray-800'>
            {patientAttendantDetails?.mobile
              ? `+91-${patientAttendantDetails?.mobile}`
              : '-'}
          </span>
        </p>
        <button className='mt-2 rounded-lg border border-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
          onClick={()=>{
            navigation(`/dashboard/case-history/${caseId}`)
          }}
        >
          View Details
        </button>
      </div>
    </div>
  )
}
